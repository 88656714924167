<template>
  <section id="create-display-campaign">
    <div>
      <b-row class="mb-5">
        <b-col md="10">
          <h1>{{ $t('adsDisplay.campaigns') }}</h1>
          <h5 class="text-primary">
            {{ $t('adsDisplay.confCampAd') }}
          </h5>
        </b-col>
        <b-col class="d-flex justify-content-end mr-1">
          <router-link to="/DisplayAdvertising/campaigns">
            <b-button variant="danger">
              {{ $t("dataGeneric.goBack") }}
            </b-button>
          </router-link>
        </b-col>
      </b-row>
      <b-overlay
        variant="transparent"
        :show="show"
      >
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          :finish-button-text="$t('dataGeneric.create')"
          :back-button-text="$t('dataGeneric.goBack')"
          :next-button-text="$t('next')"
          class="mb-3"
          @on-complete="formSubmitted"
        >
          <!-- Información del contenido tab -->
          <tab-content
            :title="$t('advertising.CampaignInfo')"
            :before-change="validationForm"
          >
            <validation-observer
              ref="accountRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    {{ $t("advertising.CampaignInfo") }}
                  </h5>
                  <small class="text-muted">{{
                    $t("advertising.CampaignInfoSub")
                  }}</small>
                </b-col>

                <b-col
                  md="6"
                  sm="12"
                >
                  <b-form-group :label="$t('message.tableHeader.name') + ' *'">
                    <validation-provider
                      v-slot="validationContext"
                      name="name"
                      rules="required|min:3"
                    >
                      <b-form-input
                        id="name"
                        v-model="name"
                        :state="validationContext.errors.length > 0 ? false : null"
                        :placeholder="$t('firstName')"
                        maxlength="150"
                      />
                      <small class="text-danger">{{ messageValidation(validationContext) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="6"
                  sm="12"
                >
                  <b-form-group :label="$t('dataGeneric.description')">
                    <validation-provider
                      v-slot="{ errors }"
                      name="description"
                    >
                      <b-form-textarea
                        v-model="comment"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('advertising.CampaignDescription')"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <tab-content
            :title="$t('message.tableHeader.date')"
            :before-change="validationFormSocial"
          >
            <validation-observer
              ref="socialRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    {{ $t("message.tableHeader.date") }}
                  </h5>
                  <small class="text-muted">
                    {{ $t("advertising.CampaignDateRange") }}</small>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    v-slot="validationContext"
                    name="inicioDateTime"
                    rules="required"
                  >
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('advertising.CampaignDateStart')+' *'"
                    >
                      <flat-pickr
                        id="inicioDateTime"
                        v-model="inicioDateTime"
                        :state="validationContext.errors.length > 0 ? false : null"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          enableSeconds: true,
                          dateFormat: 'Y-m-d H:i:S'
                        }"
                      />
                      <small class="text-danger">{{ messageValidation(validationContext) }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    v-slot="validationContext"
                    name="finalDateTime"
                    rules="required"
                  >
                    <b-form-group
                      class="mb-2 mr-1"
                      :placeholder="$t('contents.datePublishing')"
                      :label="$t('advertising.CampaignDateEnd')+' *'"
                    >
                      <flat-pickr
                        id="finalDateTime"
                        v-model="finalDateTime"
                        :state="validationContext.errors.length > 0 ? false : null"
                        class="form-control "
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i:S'
                        }"
                      />
                      <small class="text-danger">{{ messageValidation(validationContext) }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </b-overlay>
    </div>
  </section>
</template>

<script>
import {
  isEmpty, isUserInGroup, messageError, showToast,
} from '@/store/functions'

import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import {
  BFormTextarea,
  BRow,
  BCol,
  BOverlay,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'

import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import { getUserData } from '@/auth/utils'

import * as constants from '@core/utils/constants'

export default {
  components: {
    BOverlay,
    BButton,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      userData: getUserData(),
      name: '',
      externalFillRate: null,
      comment: '',
      inicioDateTime: null,
      isPriority: false,
      isSponsor: false,
      finalDateTime: null,
      show: false,
      required,
      tabIndex: 0,
      checkResults: [],
      checkRemove: [],
      requiredField: false,
    }
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = isUserInGroup(this.userData, constants.sponsor)
    }
    this.getCheckClient()
    this.inicioDateTime = new Date()
    const fechafinal = new Date()
    this.finalDateTime = fechafinal.setDate(fechafinal.getDate() + 1)
  },

  methods: {
    messageValidation(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('required')
      }
      if (existeClave.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    getCheckClient() {
      axios
        .post('', {
          query: `
        {
          allClients(clientId:"${this.userData.profile.client.clientId}") {
            edges {
              node {
               id
                externalFillRate
               name
              }
            }
          }
        }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.externalFillRate = result.data.data.allClients.edges[0].node.externalFillRate
        })
        .catch(() => { })
    },
    formSubmitted() {
      this.show = true
      if (isEmpty(this.inicioDateTime)) {
        this.inicioDateTime = new Date()
      }
      if (isEmpty(this.finalDateTime)) {
        const fechafinal = new Date()
        this.finalDateTime = fechafinal.setDate(fechafinal.getDate() + 1)
      }

      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
        mutation($name: String!, $description: String, $client: ID!, $start: DateTime!, $end: DateTime!){
          createDisplayCampaign(input:{
            name: $name,
            description: $description,
            client: $client,
            startDate: $start,
            finalDate: $end,
          }){
          displayCampaign {
            id
            name
            description
            startDate
            finalDate
          }
        }
        }
      `
      const variables = {
        name: this.name,
        description: this.comment,
        client: this.userData.profile.client.id,
        start: new Date(this.inicioDateTime),
        end: new Date(this.finalDateTime),
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)

      axios.post('', data, config).then(res => {
        messageError(res, this)

        this.show = false
        showToast(this.$t('success'), 1, this)

        if (res.status === 200) {
          this.$router.push('/displayAdvertising/campaigns')
        }
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#create-display-campaign .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#create-display-campaign .head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#create-display-campaign .wizard-card-footer {
  padding: 20px;
}

#create-display-campaign .required {
  border: 1px solid;
  border-color: #ea5455 !important;
}
</style>
